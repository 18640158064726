module.exports = new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = '#{EXTENSIONMICROURL}#/extensionEntry.js';
    
    script.onerror = () => {
      reject(new Error('Failed to load remote script.'));
    };

    script.onload = () => {
      const proxy = {
        get: (request) => window.extension.get(request),
        init: (arg) => {
          try {
            return window.extension.init(arg);
          } catch(e) {
            console.log('remote container already initialized', e);
          }
        }
      };
      resolve(proxy);
    };

    document.head.appendChild(script);
  });